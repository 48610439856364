.multiple-tag-modal {
  .MuiAutocomplete-hasClearIconMui
    .MuiFilledInput-root {
    background-color: #fff !important;
    padding: 0px !important;
    border: 1px solid #dddddd !important;
    border-radius: 0.25rem !important;
  }
 
    .MuiInputBase-root::before {
      border-bottom: none !important;
      border-color: unset !important;
  }
  .MuiInputBase-root::after {
    border-bottom: none !important;
    border-color: unset !important;
}
  .MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input {
    padding: 0.75rem  !important;
  }
  .MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: none !important;
    border-color: unset !important;
  }
  .MuiAutocomplete-root .MuiFilledInput-root {
    background-color: #fff !important;
    padding: 0px !important;
    border: 1px solid #dddddd !important;
    border-radius: 0.25rem !important;
  }
  .MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: none !important;
    border-color: unset !important;
  }
  .MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: none !important;
    border-color: unset !important;
  }
   
}
