.background-image-testmonial {
  background-image: url("../../../assets/images/homepage/optimised/fondinscrir.jpg");
  min-height: 146px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px;
  .btn-testmonial {
    color: #003030;
  }
}
