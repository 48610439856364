.logodiv{
    background: #002C2C !important;
}
.rightdiv{
    float: left;
}

.bgImage{
  background-image: url("../../../../public/round_grey.svg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.borderRadius{
  border-radius: 15px !important;
  box-shadow: 0 0 14px rgb(0 48 48 / 20%)!important;
}


  .location-icon {
    
    // margin: 60px 0px 0px 20px;
    display: flex;
    height: 25px;
    width: 25px;
    flex-direction: column-reverse;
  }

  .phone-icon {
    // margin: 20px 0px 0px 20px;
    display: flex;
    height: 25px;
    width: 25px;
    flex-direction: column-reverse;
  }
  .email-icon {
    // margin: 30px 0px 0px 20px;
    display: flex;
    height: 25px;
    width: 25px;
    flex-direction: column-reverse;
  }
 .cardStyle {
    height: 295px;
    margin-top : -100px !important;
    max-width: 350px;
}
.location-style{
    font-family: "OpenSans" !important;
    margin-top: -25px !important;
}
.phone-style{
    font-family: "OpenSans" !important;
    margin: -16px 30px 0px 0px !important;
}
.mail-style{
    font-family: "OpenSans" !important;
    margin: -19px 0px 0px 48px !important;
}
.locplus-style{
    font-family: "OpenSans" !important;
    margin-right: 25px !important;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top:20px;
    
  }

  .card-shadow-contact {
    box-shadow: 0 0 14px rgb(0 48 48 / 20%);
   
  }