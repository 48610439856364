.react-images__view-image {
  margin: auto;
  width: 80%;
  padding: 50px 0px;
}
.react-photo-gallery--gallery div {
  visibility: hidden;
}

.react-photo-gallery--gallery div > :first-child {
   visibility: visible;
// height: 24rem;
 }

.react-gallery{
  visibility: visible;
  height: 12rem !important;
}

.algincenter {
  margin-left: 17rem !important;
}

.color {
  border-color: #dfbba2;
}
.textcolor {
  color: #dfbba2;
}
.btnWidth {
  width: -webkit-fill-available !important;
}
.shadow {
  box-shadow: 0px 0px 0px 0px rgb(255 0 20 / 20%),
    0px 0px 0px 0px rgb(96 0 0 / 20%) !important;
  border: 1px solid #dddddd;
}

.heading-linear-price {
  background: -webkit-linear-gradient(45deg, #dfbba2, #c49676);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
