.modal-select-main {
  .MuiSelect-select {
    padding: 1rem 2rem 0.75rem 0.75rem !important;
    --tw-border-opacity: 1;
    border-color: #dddddd !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: unset !important;
    border-width: 1px;
  }
}
