.image-wrapper {
  .img-top {
    display: none;
  }

  .image {
    opacity: 1;
  }

  .image-two {
    opacity: 0;
  }
  
  .card:hover {
    .img-top {
      display: block;
      transform: scale(1.06)
    }

    .image {
      // display: none;
      opacity: 1;
    }

    .image-two {
      opacity: 1;
      transform: scale(1.06)
    }
  }
}
