.filter-main {
  .number-input-for-filter {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.postal-code-field-container {
  width: 100%;
}

.postal-code-field {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  text-indent: 15px;
  outline: none;
  font-family: "OpenSans";
  font-size: 14px;
  font-weight: regular;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.postal-code-selected {
  display: flex;
  // flex-wrap: wrap;
}

.postal-code-tag {
  color: white;
  font-family: "OpenSans";
  font-size: 14px;
  width: fit-content;
  background: #003030;
  box-sizing: border-box;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.postal-code-remove {
  color: #ccc;
  margin-left: 15px;
}

.react-tags-wrapper {
  width: 100%;
}

.postal-code-suggestions {
  background-color: white;
  box-sizing: border-box;
  padding: 0px 10px;
  font-family: 'OpenSans';
  max-height: 150px;
  overflow-y: auto;

  & ul {
    & li {
      height: 35px;
      cursor: pointer;
    }
  }

  & mark {
    background-color: transparent;
  }
}

.postal-code-active-suggestion {
  // height: 35px;
  // text-indent: 15px;
}
.hoverEffect:hover{
  background-color: #07380b;
}