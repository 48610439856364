.radius{
    border-radius: 15px !important;
}
.ptag{
    display: inline-block
}
.row1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .block1 {
    width: 150px;
  }
  .circular--square {
    width: 50px;
    height: 50px; 
    border-top-left-radius: 50% 50%; 
    border-top-right-radius: 50% 50%; 
    border-bottom-right-radius: 50% 50%; 
    border-bottom-left-radius: 50% 50%; 
}