.animated {
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}