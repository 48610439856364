.footer {
    &-container {
        height: 377px;

        @media (max-width: 600px) {
            height: auto;
        }

        &-sub {
            height: 278px;

            @media (max-width: 600px) {
                height: auto;
            }
        }
    }
    &-input {
        resize: none;
        
        &::placeholder {
            color: white;
        }
    }
}