.service-home-main {
  .image-one {
    background-image: url("../../../assets/images//homepage/one.png");
  }
  .image-two {
    background-image: url("../../../assets/images//homepage/two.png");
  }
  .image-three {
    background-image: url("../../../assets/images//homepage/three.png");
  }
  .image-four {
    background-image: url("../../../assets/images//homepage/four.png");
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
  }
  .grid {
    position: relative;
  }

  /* Common style */
  .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .grid figure img {
    position: relative;
    display: block;
  }

  .grid figure figcaption {
    padding: 2em;
    color: #fff;
    // font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .grid figure figcaption::before,
  .grid figure figcaption::after {
    pointer-events: none;
  }

  .grid figure figcaption,
  .grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
  }

  // .grid figure h2 {
  //   word-spacing: -0.15em;
  // }

  .grid figure h2 span {
    font-weight: 800;
  }

  .grid figure h2,
  .grid figure p {
    margin: 0;
  }

  .grid figure p {
    letter-spacing: 1px;
    // font-size: 60.5%;
  }

  figure.effect-sadie figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #003030ba;
    border-radius: 10px;
    content: "";
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  figure.effect-sadie h2 {
    position: absolute;
    top: 46%;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.35s, color 0.35s;
    transition: transform 0.35s, color 0.35s;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  figure.effect-sadie figcaption::before,
  figure.effect-sadie p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }

  figure.effect-sadie p {
    position: absolute;
    top: 30%;
    left: 0;
    // padding: 2em;
    width: 100%;
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  figure.effect-sadie:hover h2 {
    color: #fff;
    -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
    transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
  }

  figure.effect-sadie:hover figcaption::before,
  figure.effect-sadie:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.opacity-75 {
  opacity: 75%;
}
