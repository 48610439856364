.vertical-stepper-for-mobile-main {
  .MuiStepConnector-vertical {
    padding: 0px;
    margin-left: 36px;
  }
  .MuiStepConnector-lineVertical {
    border-left-width: 6px;
    border-color: #f1f4f6;
  }
}
