.main {
    &-search {
        &-container {
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);

            &-tab {
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
            }
        }

        &-input {
            ::placeholder {
                color: #949AA4;
            }
        }
    }
}

.hoverEffect:hover{
    background-color: #07380b;
  }