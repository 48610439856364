.coming-soon {
    &-icon {
        &-container {
            display: flex;
            align-items: center;
            margin-left: 100px;

            @media (max-width: 600px) {
                margin-left: 15px;
            }

            &-main {
                display: flex;
                align-items: center;
            }
        }
    }
}