.modal-main {
  .checkbox-modal {
    .MuiFormControlLabel-label {
      font-weight: 400;
      font-family: "OpenSans";
      font-size: 16px;
      letter-spacing: unset;
      color: #666666;
    }
  }
  
}
